"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var swiper_slider_1 = require("./swiper-slider");
require("core-js/stable/dom-collections/for-each");
require("./../scss/_variables");
require("bootstrap-sass/assets/stylesheets/bootstrap");
require("./../scss/slider");
require("./../scss/main");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
        this.initSlider();
    };
    Application.prototype.initSlider = function () {
        var sliders = document.querySelectorAll('.j-slider-component');
        sliders.forEach(function (slider) {
            new swiper_slider_1.SwiperSlider({
                element: slider,
                options: {}
            }).run();
        });
    };
    return Application;
}());
document.addEventListener('DOMContentLoaded', function () {
    new Application().run();
});
